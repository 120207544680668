
document.addEventListener('DOMContentLoaded', function () {

    /** Menu animation */
    const el_menu = document.getElementById('admin_menu');
    let open_menu = false;

    if (el_menu) {
        el_menu.style.left = `${el_menu.offsetWidth * -1}px`;
    }

    document.addEventListener('mousemove', function (event) {
        const mouseX = event.clientX;

        if (!open_menu && el_menu) {
            if (mouseX <= 2) {
                el_menu.style.left = 0;
                el_menu.style.opacity = 1;
                open_menu = true;
            } else {
                el_menu.style.left = `${el_menu.offsetWidth * -1}px`;
            }
        }
    });

    el_menu.addEventListener('mouseleave', function (event) {
        if (open_menu && el_menu) {
            el_menu.style.left = `${el_menu.offsetWidth * -1}px`;
            open_menu = false;
        }
    });

});